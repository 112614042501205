import React, {  useState } from "react";

import { FormAndLiveness } from "@ahrvocomply/ahrvo-comply-react-sdk-sandbox";

const App = () => {
  const [token, setToken] = useState("");
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      {!confirm && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5rem",
            flexDirection: "column",
          }}
        >
          <input
            style={{
              width: "30rem",
              height: "3rem",
              border: "1px solid #ddd",
              fontSize: "1rem",
              borderRadius: "8xp",
              paddingLeft: "1rem",
            }}
            type="text"
            placeholder="Please enter token here to proceed"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <button
            style={{
              width: "7rem",
              height: "2rem",
              fontSize: "1rem",
              background: "blue",
              color: "white",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!token) {
                alert("Please enter token");
                return;
              }
              setConfirm(true);
            }}
          >
            Proced
          </button>
        </div>
      )}

      {confirm && (
        <FormAndLiveness
          client_webapp_url="https://google.com"
          nftid="testnet"
          onComplete={() => alert("process is complete")}
          token={token}
        />
      )}
    </div>
  );
};

export default App;
